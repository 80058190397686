
.cj-input-text-password
{
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

    &.hideRequired {
      .top-container {
        display: none;
      }
    }

    &.disabledInput input {
      background-color: #ecedf2;
    }
     
    .input-wrapper {
      position: relative;
    }
      
    .input {
      box-sizing: border-box;
      color: #191919;
      width: 100%;
      
      border-radius:10px;
      background: white;
      text-decoration: none;
      outline: none;
      // font-family:"Rubik";
      
      display: flex;
      align-items: center;
      border:1px solid #E6E6E6;
      border-radius: 5px;
      height: 40px;
      width: 100%;
      font-size: 14px;
      padding-left: 1rem;
      font-family: "Montserrat";

    }
    .input::placeholder
    {
      color: #a0a0a3;
    }
    .input-icon {
      color: #191919;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
    .input-icon-password {
      color: #a0a0a3;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      left: unset;
      right: 12px;
      cursor: pointer;
      font-size: 12px;
    }
    .top-container
    {
      display: flex;
      justify-content: space-between;
      .title
      {
        font-size: 14px;
        font-weight: 500;
        color:#080808 ;
        height: 28px;
        line-height: 28px;
      }
      .obligatory
      {
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        color:#a0a0a3;
      }
    }
    .red
    {
      color: red!important;
    }
}