.TeamAndContactUs {
    .smartInversion {
        position: relative;

        .background {
            position: absolute;

            object-fit: cover;
            width: 100%;
            height: 779px;

        }

        .parent-smartInversion {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 160px;
            grid-row-gap: 0px;
            z-index: 3;
            position: absolute;
            height: 779px;
            width: 100%;

            @media screen and (max-Width:1000px) {
                display: flex;
                flex-direction: column;
                margin-top: 250px;
                height: fit-content;
                padding: 0 20px;
            }

            .col-smartInversion1 {
                grid-area: 1/1/2/2;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 15%;

                @media screen and (max-Width:1024px) {
                    padding-bottom: 40%;
                }

                @media screen and (max-Width:1000px) {
                    padding-bottom: 0;
                    align-items: center;
                    justify-content: center;
                }

                .container-info-smartInversion {
                    @media screen and (max-Width:1000px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .text-content {
                        font-size: 40px;
                        font-weight: bold;
                        color: #070C35;
                        width: 400px;
                        text-align: center;

                        @media screen and (max-Width:1000px) {

                            width: 100%;
                            font-size: 22px;
                        }
                    }

                    .buttonInverssion {
                        width: 100%;
                        height: 50px;
                        border-radius: 50px;
                        border: 2px solid #2FE2FC;
                        background: transparent;
                        color: white;
                        font-size: 16px;
                        margin-top: 50px;
                        cursor: pointer;

                        @media screen and (max-Width:1000px) {
                            width: 90%;
                            font-size: 12px;
                        }
                    }

                    .buttonInverssion:hover {
                        transform: scale(1.1);
                        transition: transform 0.2s linear;
                    }
                }

            }

            .col-smartInversion2 {
                grid-area: 1 / 2 / 2 / 3;
                display: flex;
                align-items: flex-end;

                .pc-smartInverssion {
                    width: 80%;

                    @media screen and (max-Width:1024px) {

                        padding-bottom: 33%;
                    }

                    @media screen and (max-Width:1000px) {

                        padding-bottom: 0;
                        width: 55%;
                    }

                }

                @media screen and (max-Width:1000px) {
                    align-items: center;
                    justify-content: center;
                }

            }
        }
    }
}

.contactUs {
    width: 100%;
    height: 792px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 15%, rgba(206, 222, 248, 1) 80%);
    margin-top: 792px;
    padding-bottom: 20px;

    @media screen and (max-Width:1230px) {
        flex-direction: column;
        height: fit-content;
        margin-top: 842px;

    }

    .parentcontactUs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 90px;
        grid-row-gap: 0;
        height: 100%;

        @media screen and (max-Width:1230px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .colparentcontactUs1 {
            grid-area: 1 / 1 / 2 / 2;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 0%;

            @media screen and (max-Width:1230px) {
                justify-content: center;
                margin-bottom: 10%;
            }

            .img-contactus {
                width: 60%;

            }
        }

        .colparentcontactUs2 {
            grid-area: 1 / 2 / 2 / 3;

            @media screen and (max-Width:1230px) {
                display: flex;
                justify-content: center;
            }

            .second-column-container {
                width: 550px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (max-Width:1230px) {
                    width: 95%;
                    padding: 0 20px;
                    text-align: center;
                }

                .ontactUsTitle {
                    font-size: 34px;
                    color: #1583EB;
                    font-weight: bold;
                    margin-bottom: 40px;

                }

                .contactUsText {
                    color: #1F264A;
                    font-size: 15px;
                    font-weight: 600;
                }

                .input-text-container {
                    margin-top: 20px;
                    display: flex;
                    gap: 20px;

                    .input50 {
                        width: 50%;
                    }
                }

                .input-text-container-ONE {
                    margin-top: 20px;
                }

                .btn-gradient {
                    border: none;
                    border-radius: 50px;
                    height: 50px;
                    width: 200px;
                    background: linear-gradient(90deg, rgba(47, 226, 252, 1) 15%, rgba(21, 131, 235, 1) 80%);
                    color: white;
                    font-weight: 500;
                    font-size: 18px;
                    cursor: pointer;
                }

                .btn-gradient:hover {
                    transform: scale(1.1);
                    transition: transform 0.2s linear;
                }
            }
        }
    }

    .p-toast .p-toast-message.p-toast-message-info {
        background: #e9e9ff !important;
        border: solid #69cdffc7 !important;
        border-width: 0 0 0 6px !important;
        color: #13a0f3e0 !important;
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: #13a0f3e0 !important;
    }
}