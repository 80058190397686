#us {
    @media (max-width: 1280px) {
        height: 100%;
        padding: 50px 0 100px 0;
    }

    @media (max-width: 820px) {
        height: 80vh;
    }

    @media (max-width: 768px) {
        height: 100%;
        padding: 50px 0;
    }

    @media (max-width: 480px) {
        padding: 20px 0;
        // width: 101%;
    }

    width: 100%;
    height: 100vh;
    background-image: url('../../../img/background_blue.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


    .mainContainer {
        @media (max-width: 1024px) {
            max-width: 900px;
            align-items: flex-start;
            gap: 20px;
            padding: 50px 20px 0 20px;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 480px;
            padding: 50px 0px 0 0px;
        }

        @media (max-width: 480px) {
            display: flex;
            flex-direction: column;
            align-items: initial;
            max-width: 330px;
            padding: 50px 0px 0 0px;
        }

        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
        gap: 50px;

        .image {

            @media (max-width: 480px) {
                height: 40%;
            }

            display: flex;
            justify-content: left;
            align-items: center;

            img {
                @media (max-width: 1280px) {
                    width: 400px;
                    height: 400px;
                }

                @media (max-width: 1024px) {
                    width: 350px;
                    height: 350px;
                }

                @media (max-width: 768px) {
                    width: 311px;
                    height: 300px;
                }

                width: 500px;
                height: 500px;
            }
        }

        .context {
            @media (max-width: 768px) {
                align-items: center;
            }

            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: justify;

            h1 {
                @media (max-width: 1024px) {
                    font-size: 36px;

                }

                font-size: 46px;
            }

            p {
                @media (max-width: 1024px) {
                    font-size: 16.5px;

                }

                font-size: 20px;
                color: white;
            }

            .btnServices {
                @media (max-width: 480px) {
                    width: 290px;
                }

                display: grid;
                place-items: center;
                color: white;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 14px;
                width: 350px;
                height: 50px;
                border-radius: 50px;
                background-image: linear-gradient(to right, #1A4DA9, #1583EB, #2FE2FC);
                margin-top: 30px;
                cursor: pointer;
                border: none;

                &:hover {
                    transform: scale(1.1);
                    transition: transform .2s linear;
                }
            }
        }

    }
}