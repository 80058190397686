.Header {

    // @media (max-width: 1600px) {
    //     padding: 0 20px;
    // }
    @media (max-width: 1024px) {
        padding: 10px 20px;
        height: 50px;
    }

    @media (max-width: 768px) {
        padding: 10px 0px;
    }
    
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 20px;
    justify-content: center;
    background-color: #cbe2ff;
    position: fixed;
    z-index: 9;

    .container{
        max-width: 1200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        @media (max-width: 1024px) {
            height: 30px;
        }

        height: 50px;
        cursor: pointer;
    }

    .options {
        @media (max-width: 1280px) {
            gap: 20px;
        }

        display: flex;
        gap: 30px;

        h3 {
            font-weight: 400;
            font-size: 16px;

            @media (max-width: 1024px) {
                font-size: 16px;
            }

            cursor: pointer;

            &:hover {
                color: #3db5ff;
            }
        }

        .navLink-l{
            color: #000;
            text-decoration: none;
        }

        .selected {
            color: #44abff;
        }
        .normal{
            color: black;
        }
    }

    .icons {
        display: flex;
        gap: 10px;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    .ChagueIdiom
    {
        align-items: center;
        display: flex;
        img
        {
            padding: 5px;
        }
    }
}