.Services {
    .title {
        width: 100%;
        color: #070C35;
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .container-rigth {
        width: 70%;
        margin-left: 26%;
        @media screen and (max-width:1023px) {
            width: 100%;
            margin-left: 0;
            margin: 0 20px;
        }


        .text {
            @media screen and (max-width:480px) {
                font-size: 18px;
            }
            font-size: 20px;
            font-weight: 400;
            text-align: justify;
            width: 100%;

        }
    }

    .titleLow {
        @media screen and (max-width:480px) {
            font-size: 36px;
        }
        width: 100%;
        color: #2FE2FC;
        font-size: 37px;
        font-weight: bold;
        margin-bottom: 30px;
        @media screen and (max-width:1023px) {
            text-align: center;
        }

    }

    .parentServices {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        position: relative;
        z-index: 1;

        @media screen and (max-width:1023px) {
            display: flex;
            flex-direction: column-reverse;
        }

        .colServices1 {
            grid-area: 1 / 1 / 2 / 2;
            @media screen and (max-width:1023px) {
                display: flex;
                justify-content: center;
            }
        }

        .colServices2 {
            grid-area: 1 / 2 / 2 / 3;
            display: flex;
            align-items: center;

            .imgServices {
                width: 100%;
                position: relative;
                top: -63px;
                right: 0px;
            }
        }
    }

    .carrouselSection {
        @media screen and (max-width:480px) {
            height: 700px;
        }
        position: relative;
        background: #F7F9FF;
        height: 922px;

        .backgroundGray {
            width: 100.4%;
            position: relative;
            top: -278px;
            z-index: 0;
            @media screen and (max-width:1024px) {
                display: none;
            }
        }

        .contentCarrouselSection {
            position: absolute;
            top: 10px;
            width: 100%;
            margin-top: 50px;
            // height: 100%;

            .titleCarrousel {
                @media screen and (max-width:480px) {
                    font-size: 36px;
                }
                color: #1583EB;
                font-size: 37px;
                font-weight: bold;
                width: 100%;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        width: 100%;
        text-align: center;

    }

    .carrouselPosition {
        margin-top: 130px;
        position: relative;

        .celPhone {
            position: absolute;
        }
    }

    .graphicDesign {
        .designImagesSection {
            @media screen and (max-width:480px) {
                margin-top: 30px;
            }
            margin-top: 100px;
            width: 100%;
            display: flex;
            justify-content: center;

            .designImagesPosition {
                width: 65%;
                flex-wrap: wrap;
                justify-content: space-around;
                display: flex;
                row-gap: 65px;
                gap: 28px;

                .imgDesign {
                    width: 300px;
                    // border: 20px solid #F7F9FF;
                    border-radius: 10px;
                    -webkit-box-shadow: 6px 4px 5px 0px rgba(0, 0, 0, 0.38);
                    -moz-box-shadow: 6px 4px 5px 0px rgba(0, 0, 0, 0.38);
                    box-shadow: 6px 4px 5px 0px rgba(0, 0, 0, 0.38);
                }

                .imgDesign:hover {
                    // transform:scale(1.2) ;
                    transform: scale(1.1);
                    transition: transform .2s linear;

                }
            }
        }
    }

    .container-button {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 80px;
        margin-bottom: 10px;

        .cuoter {
            background: linear-gradient(90deg, rgba(47, 226, 252, 1) 15%, rgba(21, 131, 235, 1) 80%);
            height: 50px;
            border: none;
            outline: none;
            font-size: 14px;
            padding: 0 60px;
            border-radius: 50px;
            color: white;
            font-weight: 500;
            cursor: pointer;
            margin-bottom: 30px;

            &:hover {
                transform: scale(1.1);
                transition: transform .2s linear;
            }
        }
    }

}