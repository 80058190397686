.disabledTextArea textarea {
    background-color: #ecedf2;
}
.containTextArea {
    height: 150px;
    max-height: 168px;
    min-height: 150px;
    border: 1px solid #E6E6E6;
    padding: 9px;
    border-radius: 5px;
    resize: none;
    outline: none;
    // opacity: .5;
    font-size: 14px;
    width: 100%;
    font-family: "Montserrat";
}
.textareaRequired {
    color: #E6E6E6  !important;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
}

.textareaLabel {
    font-size: 14px;
    font-weight: 500;
    height: 28px;
    line-height: 28px;
}