.Footer {
    background: #1F264A;

    .parentFooter {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-top: 50px;

        .titleFooter {
            color: #2FE2FC;
            font-size: 18px;
            font-weight: 500;
        }

        .planeTextFooter {
            color: white;
            font-weight: 400;
            font-size: 15px;
            text-decoration: none;
        }

        .colFooter1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .colFooter2 {
            grid-area: 1 / 2 / 2 / 3;
            display: flex;
            // align-items: center;
            flex-direction: column;

            margin-bottom: 100px;

            .logo_footer {
                width: 50%;
            }

            .container-icons-in {
                display: flex;
                gap: 10px;
                margin-top: 50px;
                margin-left:36px;

                img {
                    width: 20px;
                }
            }
        }

        .colFooter3 {
            grid-area: 1 / 3 / 2 / 4;
            display: flex;
            flex-direction: column;
            gap: 20px;

            a{
                text-decoration: none;
            }
        }

        .colFooter4 {
            grid-area: 1 / 4 / 2 / 5;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .colFooter5 {
            grid-area: 1 / 5 / 2 / 6;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .colFooter6 {
            grid-area: 1 / 6 / 2 / 7;
        }
    }
    .footerfinal
    {
        width: 100%;
        height: 50px;
        background: #171E3D;
        @media screen and (max-width:1230px) {
            display: none;
        }
    }
    @media screen and (max-width:1230px) {
        .parentFooter
        {
            display: flex;
            padding-top: 0px;
            .colFooter1
            {
                display: none;
            }
            .colFooter2
            {
                width: 100%;
                flex-direction: initial;
                justify-content: space-between;
                margin-bottom:0px;
                .logo_footer
                {
                    width: 150px;
                    height: 50px;
                }
                .container-icons-in
                {
                    margin-top: 0px;
                    margin-left: 0px;
                    align-items: center;
                    img
                    {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .colFooter3
            {
                display: none;
            }
            .colFooter4
            {
                display: none;
            }
            .colFooter5
            {
                display: none;
            }
            .colFooter6
            {
                display: none;
            }
        }
    }
}
@media screen and (max-width:1230px) {
    .Footer
    {
        padding: 10px 50px;
            
    }
}