.PricePackages {
    min-height: calc(100vh - 215px);
    
    .headerPackages {
        width: 100%;
        height: 64px;
        position: fixed;
        // background: #CEDEF8;
        top: 0;
        left: 0;
        z-index: 3;
        background: white;
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.19);
        -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.19);
        -moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.19);

        .parentheaderPackages {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            height: 100%;

            .colHeader1 {
                grid-area: 1 / 1 / 2 / 2;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .colHeader2 {
                grid-area: 1 / 2 / 2 / 3;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .colHeader3 {
                grid-area: 1 / 3 / 2 / 4;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .back {
            width: 30px;
            height: 30px;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }


        .logoOnly {
            width: 40px;
        }
    }

    .titleHeader {

        font-size: 26px;
        font-weight: bold;
        text-align: center;
        margin-top: 95px;
        margin-bottom: 49px;

    }

    .contentCards {
        display: flex;
        gap: 55px;
        margin-top: 6%;
        align-items: center;
        width: 100%;
        justify-content: center;
        padding: 0 5%;
        flex-wrap: wrap;
        row-gap: 100px;
        background: #F6F9FF;
        @media screen and (max-width:1532px) {
        
            padding-bottom: 100px; 
        }
    }

    .imgCard {
        position: absolute;
        z-index: -1;
        height: calc(100vh - 102px);
        width: calc(100vw - 10px);
        object-fit: cover;
        object-position: top;
    }
}