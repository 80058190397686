.HeaderMobile {
    // @media (max-width: 1600px) {
    //     padding: 0 20px;
    // }
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    background: #c7d7fa;
    position: fixed;
    z-index: 4;

    .btnMain {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .name {

        display: flex;
        align-items: center;

        .left_arrow{
            cursor: pointer;
            margin-right: 15px;
        }

        h2 {
            font-family: 'Montserrat';
            font-size: 22px;

        }

        span {
            font-weight: 400;

        }
    }

}

.p-sidebar {
    background: #1F264A !important;
    color: #3e444e !important;

    .p-sidebar-header .p-sidebar-close {
        width: 1rem !important;
        height: 1rem !important;

        .pi {
            font-size: 0.5rem !important;
            color: white !important;
        }
    }



    .p-sidebar-header {
        padding: 10px 20px 0px 0 !important;
        position: absolute !important;
        right: -45px !important;

        .p-sidebar-icon {
            border: 1px solid white !important;
        }
    }

    .p-sidebar-icon:focus {
        box-shadow: none !important;
    }

    .logo {
        width: 100%;
        border-bottom: 1px solid #9ca8c4;
        background: #9ca8c4;

        img {
            @media (max-width: 1024px) {
                height: 30px;
                width: 100px;
                margin: 10px auto;
            }
        }

        display: flex;
        width: 100%;
        justify-content: center;
        height: 50px;
        cursor: pointer;
    }

    .listOptions {
        color: white;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 0 10px;
        margin-top: 20px;

        h3 {
            font-weight: 400;
        }


    }

    .ChagueIdiom {
        align-items: center;
        display: flex;
        padding-left:15px;
        margin:10px 0;
        border-left:5px solid #2CD8FA;
        img {
            padding: 5px;
        }
    }
}

.p-sidebar-left .p-sidebar {
    width: 250px !important;

    .options {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .p-sidebar-header+.p-sidebar-content {
        padding: 0 !important;
    }

    .p-sidebar-content {
        padding: 20px !important;
    }
}

.p-button.p-button-icon-only {
    width: 36px !important;
    height: 36px !important;
    padding: 0 !important;
}

.p-button {
    background: transparent !important;
    border: 1px solid #c7d7fa !important;
    border-radius: 50% !important;
    -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.33);
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.33);
    color: black !important;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.iconsContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #9ca8c4;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}