#home {
    @media (max-width: 1700px) {
        height: 100%;
        padding-bottom: 100px;
    }

    @media (max-width: 1024px) {
        height: 100%;
        padding-bottom: 100px;
    }

    // @media (max-width: 540px) {
    //     height: 100vh;
    // }

    // @media (max-width: 540px) {
    //     height: 100%;
    //     padding-bottom: 100px;
    // }

    width: 100%;
    height: 100%;
    background-image: url('../../../img/background_index.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 100px;
    background-attachment: fixed;

    .mainContainer {
        @media (max-width: 1280px) {
            max-width: 1100px;
        }

        @media (max-width: 1024px) {
            max-width: 900px;
        }

        @media (max-width: 768px) {
            max-width: 530px;
        }

        @media (max-width: 540px) {
            max-width: 330px;
        }

        position: relative;
        top: 60px;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .contextMain {
            @media (max-width: 768px) {
                margin-top: -20px;
            }

            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 0;
            }

            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .text {
                @media (max-width: 768px) {
                    padding: 10px 0;
                    margin: 30px 0 0 0;
                    align-items: center;
                }

                display: flex;
                flex-direction: column;
                margin: 20px auto;
                gap: 20px;
                padding: 20px 20px 10px 20px;

                h1 {
                    @media (max-width: 1280px) {
                        font-size: 34px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 26px;
                        text-align: center;
                    }

                    @media (max-width: 480px) {
                        font-size: 23px;
                        text-align: center;
                    }

                    font-size: 40px;

                }

                h2 {
                    @media (max-width: 1280px) {
                        font-size: 26px;
                    }

                    @media (max-width: 1024px) {
                        text-align: center;
                        font-size: 18px;
                    }

                    font-size: 32px;
                    text-transform: uppercase;
                    background-image: linear-gradient(to right, #1A4DA9, #1583EB, #2FE2FC);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 400;
                }

                p {
                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }

                    font-size: 18px;
                    text-align: justify;
                    line-height: 25px;
                }

            }

            .image {
                @media (max-width: 768px) {
                    justify-content: center;
                }
                display: flex;
                justify-content: flex-end;
                width: 100%;

                img {
                    @media (max-width: 1280px) {
                        margin-top: 30px;
                    }

                    @media (max-width: 1024px) {
                        width: 370px;
                        height: 360px;
                    }

                    @media (max-width: 768px) {
                        width: 300px;
                        height: 328px;
                    }

                    @media (max-width: 480px) {
                        margin-top: 0;
                    }

                    width: 500px;
                    height: 400px;

                }
            }

            .btnServices {
                @media (max-width: 768px) {
                    position: relative;
                    z-index: 3;
                    width: 280px;
                }

                display: grid;
                place-items: center;
                color: white;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 14px;
                width: 350px;
                height: 50px;
                border-radius: 50px;
                background-image: linear-gradient(to right, #1A4DA9, #1583EB, #2FE2FC);
                margin-top: 30px;
                cursor: pointer;
                border: none;

                &:hover {
                    transform: scale(1.1);
                    transition: transform .2s linear;
                }
            }
        }
    }
}