body {
    margin: 0 0 0 0;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px !important;
        /* Ancho de la barra */
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        /* Color de fondo de la barra */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 40px;
    }
}

p,
h1,
h2,
h3,
h4 {
    margin: 0;
}

.MainPage {
    width: 100.0%;
    height: 100%;
    // overflow-x: hidden;

}
.ws {
    position: fixed;
    right: 10px;
    z-index: 3;
    bottom: 120px;
    cursor: pointer;
    // width: 70px;
}