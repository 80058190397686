.ModalWhatsApp {
    max-width: 200px;
    height: 200px;
    border-radius: 10px;
    background: white;
    position: fixed;
    right: 80px;
    bottom: 169px;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.19);
    max-width: 300px;
    width: 100%;
    padding: 10px;
    z-index: 4;
    
    @media screen and (max-width:400px) {
        max-width: 255px;
        right: 36px;
    }
    .message-container
    {
        display: flex;
        justify-content: space-between;
    }
    .text-message
    {
        margin-top: 20px;
        width: 100%;
        height: 85px;
        resize: none;
        outline: none;
        padding: 10px;
    }
    button
    {
        cursor: pointer;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        border: 0;
        outline: 0;
        background:  linear-gradient(90deg, rgba(47,226,252,1) 15%, rgba(21,132,235,1) 80%);
        border-radius: 100px;
        color: white;
        font-size: 15px;
        font-weight: 600;
    }
    .content-message-send
    {
        width: 100%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}