.CardPrice {
    width: 300px;
        height:  540px;
        border-radius: 10px;
        box-shadow: 0px 6px 9px 4px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 6px 9px 4px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 6px 9px 4px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: white;
        position: relative;
    .cardContent {
        display: flex;
        justify-content: center;

        .cardTitle {
            width: 200px;
            height: 70px;
            position: relative;
            top: -26px;
            border-radius: 0 0 30px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 800;
            font-size: 24px;
        }

        .dobles {
            width: 20px;
            height: 34px;
            background: #512D49;
            position: relative;
            top: -16px;
            z-index: 2;

        }

    }
    .body-content
    {
        height: 461px;
        padding: 0 35px 35px 35px;
        overflow-y: auto;
        margin-bottom: 35px;
        background: white;

    }

    .body-content::-webkit-scrollbar {
        width: 6px;
        height: 10px;
      }
      
      .body-content::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 50px;
      }
      
      .body-content::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
      
    .footerCardContainer
    {
        display: flex;
        justify-content: center;
        .footerCard
        {
            width: 200px;
            height: 70px;
            top: -26px;
            border-radius:30px 30px 0 0 ;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 800;
            font-size: 25px;
            cursor: pointer;
        }
    }
    .b-l
    {
        
        border-radius: 5px 0;
    }
    .b-r
    {
        border-radius: 0 5px;
    }
    .titleCard
    {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 500;
        text-align: center;
        
    }
    .priceCard
    {
        font-size: 50px;
        font-weight: 700;
        text-align: center;
        margin: 44px 0;
    }
    .adventaje-container
    {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin: 15px 0;
        .circle
        {
            min-width: 20px;
            height: 20px;
            border-radius: 50px;
            margin-right: 10px;
        }
        .textCard
        {
            font-size: 12px;
        }
    }
}