.cAbout {
    background-color: #f5f5f5;
    background-image: url('../../img/background_index.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    .About {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 1200px;
        width: 100%;
        align-items: center !important;
        padding: 100px 15px;
        margin: 0 auto;

        h1 {
            font-size: 26px;
            text-transform: uppercase;
            background-image: linear-gradient(to right, #1A4DA9, #1583EB, #2FE2FC);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 20px;
            }
        }

        h3{
            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        .containerCard {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;

            .cardDevs {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 100px;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .imgCard {
                    display: grid;
                    place-items: center;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background-color: #cbe2ff;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .text {
                        display: flex;
                        align-items: center;
                        gap: 10px;


                        p {
                            font-weight: 700;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .elements {
            display: grid;
            grid-template-columns: 50% 45%;
            gap: 30px;
            align-items: center;
            font-family: 'Montserrat';
            line-height: 25px;
            color: #1A4DA9;
            text-align: justify;
            margin-bottom: 70PX;

            &.seconds_element{
                flex-direction: column-reverse;
            }

            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }

            .elements_inside{
                display: flex;
                flex-direction: column;

                @media (max-width: 768px) {
                    align-items: center;
                }

                
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 10px;
            }

            a {
                text-decoration: none;
            }

            .btnServices {
                @media (max-width: 768px) {
                    position: relative;
                    z-index: 3;
                    width: 280px;
                }

                display: grid;
                place-items: center;
                color: white;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 14px;
                width: 350px;
                height: 50px;
                border-radius: 50px;
                background-image: linear-gradient(to right, #1A4DA9, #1583EB, #2FE2FC);
                margin-top: 30px;
                cursor: pointer;
                border: none;

                &:hover {
                    transform: scale(1.05);
                    transition: transform .2s linear;
                }
            }
        }
    }
}